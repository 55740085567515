import './IndependentUser.scss';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import getQueryVariable from '../utility/getQueryVariable';
import { loadSegment } from '../utility/segment';
import Loader from '../utility/Loader';
import { ValidationInput } from '../signupsurvery/ValidationFields';
import { useForm } from 'src/hooks/useForm';
import Background from '../Background';

export function IndependentUserSurvey() {
  const errorFromQuery = getQueryVariable('error');
  const emailAddress = getQueryVariable('email');
  const firstName = getQueryVariable('firstName');
  const lastName = getQueryVariable('lastName');
  const phoneNumber = getQueryVariable('phoneNumber');
  const clientId = getQueryVariable('clientId');

  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);

  const { t } = useTranslation();

  const [formState, setFormValue] = useForm({
    clientId: clientId || '',
    emailAddress: emailAddress || '',
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || ''
  });

  const [isBlurred, setBlurred] = useForm({
    firstName: false,
    lastName: false
  });

  useEffect(() => {
    document.title = t('SignupInviteTitle');
    loadSegment('invite');
  }, []);

  function isFieldValid(fieldName: string) {
    return formState[fieldName] || !isBlurred[fieldName];
  }

  const isFormValid = () => {
    return formState.firstName && formState.lastName;
  };

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setShowLoading(true);
    setErrorMessage(null);

    if (!isFormValid()) {
      setShowLoading(false);
      return;
    }
    try {
      const { data } = await Axios('/authenticate/independentusersetup', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          requestData: formState
        }
      });

      if (data) {
        window.location = data.redirectUrl;
      }
    } catch (err) {
      setErrorMessage(err.response.data.message || t('UnknownError'));
      setShowLoading(false);
    }
  }

  if (showLoading) {
    return <Loader isLoading />;
  }

  return (
    <div className="signup-survey">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://www.goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('UserSetupSurvey')}</span>
          </div>

          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}

          <form name="signupinvite" onSubmit={(e) => onSubmit(e)} className="survey">
            <div>
              <div className="row col2">
                <ValidationInput
                  fieldName="firstName"
                  validationText={t('SignupValidationMessages.FirstNameRequired')}
                  placeholder={t('SignupPlaceholders.FirstName')}
                  value={formState.firstName}
                  setFormValue={setFormValue}
                  isValid={isFieldValid}
                  setBlurred={setBlurred}
                  autoFocus={true}
                />

                <ValidationInput
                  fieldName="lastName"
                  validationText={t('SignupValidationMessages.LastNameRequired')}
                  placeholder={t('SignupPlaceholders.LastName')}
                  value={formState.lastName}
                  isValid={isFieldValid}
                  setFormValue={setFormValue}
                  setBlurred={setBlurred}
                />
              </div>

              <div className="row">
                <ValidationInput
                  fieldName="phoneNumber"
                  validationText="x"
                  placeholder={t('SignupPlaceholders.Phone')}
                  value={formState.phoneNumber}
                  setFormValue={setFormValue}
                  setBlurred={setBlurred}
                />
              </div>
            </div>

            <div className="get-started">
              <Button disabled={!isFormValid()} loading={showLoading} type="submit" className="ui primary button">
                {t('LoginButton')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
