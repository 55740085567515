import './IndependentUser.scss';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import getQueryVariable from '../utility/getQueryVariable';
import { loadSegment } from '../utility/segment';
import Background from '../Background';

export function IndependentUserAccept() {
  const email = getQueryVariable('email');
  const clientId = getQueryVariable('clientId');
  const errorFromQuery = getQueryVariable('error');
  const expires = getQueryVariable('expires');
  const token = getQueryVariable('token');
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('SignupInviteTitle');
    loadSegment('invite');
    validateLink();
  }, []);

  const validateLink = async () => {
    try {
      await Axios('/authenticate/validations/invitetoken', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          email,
          expires,
          token,
          clientId
        }
      });

      setShowLoading(false);
    } catch (error) {
      const title = t('ContactAdministrator');
      const message = error?.response?.data?.message || t('UnknownError');
      window.location.assign(`/error?title=${title}&message=${message}`);
    }
  };

  const accept = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowLoading(true);
    setErrorMessage(null);

    try {
      const response = await Axios('/authenticate/independentusersetup', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          requestData: {
            emailAddress: email,
            clientId: clientId
          }
        }
      });

      if (response && response.data && response.data.redirectUrl) {
        window.location = response.data.redirectUrl;
      }
      setShowLoading(false);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  return (
    <div className="signup-invite">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://www.goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('IndependentUserAccept')}</span>
          </div>
          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}
          <div className="invite-description">
            <span>{t('IndependentUserAcceptText')}</span>
          </div>
          <form name="signupinvite" onSubmit={accept}>
            <div className="login">
              <Button type="submit" className="ui primary button" loading={showLoading}>
                {t('IndependentUserAcceptInvitation')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
